import requestDelivery from '@/api/agency/request-delivery'

import { baseState, baseMutations } from '@/store/mixins'
import { join, split } from 'lodash'
import moment from 'moment'

export const state = {
  ...baseState,
  status: '',
  service_type_id: [],
  formModels: {},
  dateRange: [
    moment().format("DD-MM-YYYY"),
    moment().format("DD-MM-YYYY")
  ],
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_RANGE(state, date) {
    state.dateRange = date;
  },
  SET_STATUS(state, value) {
    state.status = value
  },
  SET_SERVICE_TYPE(state, value) {
    state.service_type_id = value
  },
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formModels = data;
    } else {
      split(index, ",").forEach(el => {
        state.formModels[el] = data[el];
      });
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          date_between: join(context.state.dateRange, ","),
          status: context.state.status,
          service_type_id: context.state.service_type_id,
          
        }
      },
      attributes
    )
    return requestDelivery.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  calTransportationFee(context, {delivery_id, distance_km}){
    return requestDelivery.calTransportationFee(delivery_id, distance_km)
  },
  update(context, { id, data }) {
    return requestDelivery.update(id, data)
  },
  getDistancePrice(context, { shop_id, service_type_id }) {
    return requestDelivery.getDistancePrice(shop_id, service_type_id)
  },
  async getFormViewData(context, { params, index }) {
    let response = await requestDelivery.formModel({ params: params });
    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return response;
  },

}