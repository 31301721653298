import Vue from 'vue'
import App from './App.vue'

import './index.css'
import router from './router'
import store from "./store";

// // multiple language
import i18n from './i18n'

// customer component
import './components/common'

// plugins
import VueBootstrap from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
// import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import hljs from "highlight.js";
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import VTooltip from 'v-tooltip'
import VueClipboard from 'vue-clipboard2'


import Antd  from 'ant-design-vue';

// plugins css
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/style.css'
// import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

//antd design
import 'ant-design-vue/dist/antd.css';

// color admin css
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

// checking who login as
import whois from './whois.js'

// check ability
import ability from './ability.js'


Vue.config.productionTip = false


Vue.use(Antd)

Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
// Vue.use(VueEditor)
Vue.use(VueHljs, { hljs })
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(VueClipboard)
Vue.use(whois)
Vue.use(ability)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA6fnbSNLpeJ4FeP1djG20_i4FTDdjXDLc',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(VTooltip)


Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown);

// defined prototype
Vue.prototype.$formatNumber = function(number = 0, digit = 0 ){
  if(typeof number == 'number'){
    return number.toLocaleString('en-US', { minimumFractionDigits: digit })
  }
  return '';
}

Vue.prototype.$currencyFormat = function(number, sign = '$', digit = 2 ){
  if(typeof number == 'number') {
    return sign + ' ' +  parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: digit})
  }
  return '';
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
