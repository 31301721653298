import list from "@/api/inventory/inventory-list";
import moment from "moment";

import { baseState, baseMutations } from "@/store/mixins";
import { mapKeys, camelCase } from 'lodash'

class Pagination {
  constructor(paging = {}) {
    this.currentPage = paging.currentPage ? parseInt(paging.currentPage) : 1
    this.lastPage = paging.lastPage ? parseInt(paging.lastPage) : 0
    this.perPage = paging.perPage ? parseInt(paging.perPage) : 15
    this.total = paging.total ? parseInt(paging.total) : 0
    this.from = this.perPage * (this.currentPage - 1) + 1
    this.to = this.perPage * this.currentPage
  }
}

export const state = {
	...baseState,
	shop_id: null,
	dateRange: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
};

export const mutations = {
	...baseMutations,
	RECEIVE_RESOURCES(state, records) {
    const { data, ...meta } = records
    state.resources = data
    state.pagination = new Pagination(mapKeys(meta, (v, k) => camelCase(k)))
  },
	SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
	SET_SHOP_ID(state, value){
		state.shop_id = value
	}
};

export const getters = {};

export const actions = {
	fetch(context, attributes = {}) {
		let params = Object.assign(
			{
				page: context.state.pagination.page,
				perPage: context.state.pagination.perPage,
				shop_id: context.state.shop_id,
				search: context.state.search,
				date_from: context.state.dateRange[0],
				date_to: context.state.dateRange[1]
			},
			attributes
		);
		return list.get({ params }).then(response => {
			context.commit("RECEIVE_RESOURCES", response);
			return Promise.resolve(response);
		});
	},
	async getFormViewData(context, { params }) {
		let response = await list.formModel({ params: params });
		return Promise.resolve(response);
	}
};
