<template>
    <div class="tw-flex tw-justify-between" :v-bind="$attrs">
        <span> {{ currency }} </span>
        <span class="tw-ml-2">
            {{ $formatNumber(parseFloat(value ? value : 0), digit) }}</span
        >
    </div>
</template>

<script>
export default {
    name: "accountingFormat",
    props: {
        digit: {
            type: Number,
            default: 2
        },
        currency: {
            default: "$"
        },
        value: {
            type: [Number, String],
            default: () => 0
        }
    }
};
</script>
