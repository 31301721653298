import compensate from '@/api/finance/shop-compensation'

import moment from 'moment'
import { baseState, baseMutations } from '@/store/mixins'
import { join  } from 'lodash'

export const state = {
  ...baseState,
  dateRange: [
		moment().add(-7,'day').format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
  is_clear: null,
  account_filter: null
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_TRANSACTION(state, value){
    state.transaction_date = value
  },
  SET_IS_CLEARED(state, value){
    state.is_clear = value
  },
  SET_ACCOUNT_FILTER(state, value){
    state.account_filter = value
  },
  SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
  
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage:  context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          is_shop_clear: context.state.is_clear,
          account_filter: context.state.account_filter,
          date_between: join(context.state.dateRange, ","),
        }
      },
      attributes
    )
    return compensate.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  fetchToCreate(context, attributes = {}) {
    let params = Object.assign(
      {
        page: 1,
        perPage:  -1
      },
      attributes
    )
    return compensate.get({ params })
  },
  store(context, data) {
    return compensate.store(data)
  },
  show(context, {shop_id, date}) {
    return compensate.show(shop_id, date)
  },
  toPdf(context, clearId) {
    return compensate.toPdf(clearId)
  },
  clearPreview(context, payload) {
    return compensate.clearPreview(payload)
  },
  async getFormViewData(context, { params }) {
    let response = await compensate.formModel({ params: params })
    return response;
  }
}
