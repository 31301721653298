import QueryString from "qs";
import VueCookies from 'vue-cookies'
const axios = require('axios')
// .default;

const http = axios.create({
	baseURL: null,
	headers: {
		'X-Requested-With': 'XMLHttpRequest'
	},
	paramsSerializer: params => {
		return QueryString.stringify(params, { arrayFormat: 'brackets' })
	}
})

http.interceptors.request.use(
	config => {

        const token = VueCookies.get('token');

        if (token) {
            config.headers = Object.assign(config.headers, {
              Authorization: `Bearer ${token}`
            })
        }
		return {
            ...config,
			onUploadProgress: config.onUploadProgress,
			onDownloadProgress: config.onDownloadProgress
		}
	},
	error => Promise.reject(error)
)

export default http
