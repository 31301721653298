import packaging from '@/api/inventory/product-packaging'
import { baseState, baseMutations } from '@/store/mixins'
import { split, join } from 'lodash'
import moment from 'moment'

export const state = {
  ...baseState,
  date_range: [moment().add(-1, 'day').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
  isJdConfirmed: 0,
  formModels:{
    shop: [],
    product: []
  }
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_DATE_RANGE(state, dateRange) {
    state.date_range = dateRange
  },
  SET_CONFIRMED(state, value){
    // state.isJdConfirmed = value
    state.isJdConfirmed = value
  },
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      split(index, ',').forEach(el => {
        state.formModels[el] = data[el]
      });
    }
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          date_between: join(context.state.date_range, ','),
          is_jd_confirmed: context.state.isJdConfirmed
        }
      },
      attributes
    )
    return packaging.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return packaging.store(data)
  },
  update(context, { id, data }) {
    return packaging.update(id, data)
  },
  destroy(context, { id, data }) {
    return packaging.destroy(id, data)
  },
  confirm(context, { id, data }) {
    return packaging.confirm(id, data)
  },
  convertBooking(context, data) {
    return packaging.convertBooking(data)
  },
  async getFormViewData(context, { params, index }) {
    let response = await packaging.formModel({ params: params })
    if(response){
      context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
    }
    return response;
  }
}